import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { BG, BG_DARK } from '@etiquette-ui/colors';
import { graphql, useStaticQuery } from "gatsby";
import Description from "../sections/Products/ManagerSite/description";
import Archive from "../sections/Products/ManagerSite/archive";

import '../globalStyles.css';

const ManagerSite = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 5,
    title: 'Manager Site',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[4].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[4].node.base,
    banerImgMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[4].node.base,
    features: [
      {
        en: 'Manage your Digital Menu',
        es: 'Gestiona el contenido de Digital Menu'
      },
      {
        en: 'Create and edit the items that will be present in the Digital Menu',
        es: 'Crea y edita los productos de Digital menu '
      },
      {
        en: 'Create and manage categories and subcategories',
        es: 'Crea y administra categorías y subcategorías'
      },
      {
        en: 'Add different sections in the footer of the Digital Menu',
        es: 'Agrega multiples secciones en el pie de página del Digital Menu'
      },
      {
        en: 'Get graphs on the performance of each of the elements available in the Digital Menu',
        es: 'Genera gráficas sobre el rendimiento de cada uno de los elementos de Digital Menu'
      },
      {
        en: '86’s no stock, it is a way of keeping control over the items that are available in stock',
        es: '"86’s no stock" es la manera de clasificar los productos que no están disponibles en inventario'
      },
      {
        en: 'See the history of the items that have been in 86’s',
        es: 'Visualiza el historial de cada producto que ha estado en "86’s no stock"'
      },
      {
        en: 'Archive the products that will not be available in a period of time',
        es: 'Oculta los productos que no estarán disponibles por un tiempo considerado'
      }
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <Archive />
      </Section>
    </ProductLayout >
  );
};
export default ManagerSite;

const query = graphql`
query managerSite {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "managersite-hero",
          "managersite-icon",
          "digitalmenu-hero-mobile",
          "managersite-banner",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;