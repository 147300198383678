import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns, ContainerFluid } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const Archive = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Add, archive and manage',
        es: 'Agrega, oculta o administra'
    }    
    const title = {
        en: 'your items easy',
        es: 'tus productos fácilmente'
    }
    
    return (
        <ContainerFluid>
            <ThreeColumns className='fluid'>
                <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/managersite-2.png"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="managersite-2"
                        className="img-container overflow-fluid-md"
                        imgClassName="overflowed-img"
                    />
                </div>

                <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                    <p>
                        {translateObject({
                            en: `Quickly and easily modify the status of your menu at any time, 
                            archive items that are only available on special days and return them 
                            to the menu whenever you need them. Everything and more with the Manager Site.`,
                            es: `Modifica de forma rápida y fácil tu menú en cualquier momento,
                            oculta productos fuera de temporada y habilitalos cuándo lo desees. 
                            Esto y más es parte de Manager Site`
                        })}
                    </p>
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/managersite-3.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="managersite-3"
                        className='img-container'
                        imgClassName='overflowed-img'
                    />
                </div>
            </ThreeColumns>
        </ContainerFluid>
    )
};

export default Archive;