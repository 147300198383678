import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import {
    Row,
    Space,
    Center,
    Container,
    TwoColumns,
    InfoContainer,
    ContainerFluid,
    ImageContainer
} from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Custom',
        es: 'Personaliza'
    }    
    const title = {
        en: 'your Digital Menu',
        es: 'tu Digital Menu'
    }
    
    return (
        <ContainerFluid>
            <Container>
                <Space />
                <Position location={location} />
                <Center>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                        <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
                    </h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                        {translateObject({
                            en: `Control what you want to show and how you want to show it. 
                            Each business has its own way of organizing its dishes and drinks, 
                            that’s why we have the Manager Site for you to manage everything related to your Digital Menu.`,
                            es: `Controla lo que quieres mostrar y cómo lo quieres mostrar. 
                            Cada negocio tiene su propia manera de organizar su Digital Menu y
                            por eso existe un Manager Site exclusivo para todo lo relacionado con tu Digital Menu`
                        })}                        
                    </p>
                </Center>
            </Container>
            <TwoColumns>
                <Row>
                    <Container className='nested'>
                        <InfoContainer>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ">
                                <h3>
                                    {translateObject({
                                        en: `Manage your digital menu`,
                                        es: `Gestiona el contenido de tu Digital Menu`
                                    })} 
                                </h3>
                                <p>
                                {translateObject({
                                    en: `Categories, subcategories, dishes, drinks, descriptions, prices, labels, 
                                    availability of each dish and drink, all that and more you can control with this tool.`,
                                    es: `Con esta herramienta podrás controlar tus categorías, subcategorías, platillos, 
                                    bebidas, descripciones, precios, etiquetas y mucho más.`
                                })} 
                                </p>
                            </div>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-delay='300'>
                                <h3>
                                    {translateObject({
                                        en: `Dishes and drinks performance reports`,
                                        es: `Reportes de rendmiento sobre platillos y bebidas`
                                    })} 
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `Find out which is your star dish, the best-selling and what most people ask for. 
                                        He also knows the less popular ones to design strategies that improve their performance.`,
                                        es: `Descubre cuál es tu platillo estrella, el más vendido y cuál es el más pedido por los clientes.
                                        Descubre cuál es el menos popular para diseñar estrategias de mejora.`
                                    })} 
                                </p>
                            </div>
                        </InfoContainer>
                    </Container>
                </Row>            
                <ImageContainer data-sal='slide-right' data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                    <StaticImage
                        src="../../../images/managersite-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="managersite-1"
                        className='mask-container'
                        imgClassName='img-fluid'
                    />
                </ImageContainer>
            </TwoColumns>
            
        </ContainerFluid>
    )
};

export default Description;